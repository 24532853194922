import React, { useEffect, useRef, useState } from 'react'
import { Typography, TextField, Box, Stack, Button, Table, TableBody, TableRow, TableCell } from '@mui/material'
import Layout from 'components/Layout'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'style/theme'
import ImageIcon from '@mui/icons-material/Image'
import Tabs from '@mui/material/Tabs'
import Tab from "@mui/material/Tab"
import PageBackConfirmButton from 'components/PageBackConfirmButton'
import UploadPhotoFrame from 'components/UploadPhotoFrame'
import { ConfirmModal, ConfirmModalProps } from 'components/ConfirmModal'
import { ErrorModal, ErrorModalOnCloseProps, ErrorModalProps } from 'components/ErrorModal'
import { CompleteModal, CompleteModalOnCloseProps, CompleteModalProps } from 'components/CompleteModal'
import { PhotoEditor, PhotoEditorProps } from 'components/PhotoEditor'
import { useParams, useNavigate } from "react-router-dom"
import { useAuth } from 'hooks/useAuth'
import { useSaveTempPhoto, useDeleteTempPhoto, useUploadTempPhoto } from 'api/tmpPhoto'
import { usePostPhoto } from 'api/photo'
import { useGetClinicMyself } from 'api/clinic'
import { SaveTempPhotoErrorResponse, SaveTempPhotoResponse } from 'api/tmpPhoto/responseType'
import { PostPhotoResponse } from 'api/photo/responseType'
import { PhotoObject, getInitialPhotos, getAngleName, affines } from 'types/PhotoObject'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { PhotoSave } from 'api/photo/requestType'
import OperationProof from 'components/OperationProof'
import { AxiosError } from 'axios'
import env from 'env'
import useResetQuery from 'api/useResetQuery'
import { useGetAngleNameSetting, useGetFlipSetting } from 'api/setting'
import { AngleName, FlipSetting } from 'api/setting/responseType'
import { useGetPatient } from 'api/patient'
import { Patient } from 'api/patient/responseType'

type PhotoProps = {
  id: string
  examId: string
  clinicId: string
}

type ConfirmModalOnCloseProps = {
  modalStatus: string
}

type EditorModalOnCloseProps = {
  modalStatus: string
  image: PhotoObject
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  tabValue: number
}

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const UploadPhoto = () => {
  const urlParams = useParams<PhotoProps>()
  const id = urlParams.id ? parseInt(urlParams.id) : 0
  const clinicId = urlParams.clinicId ?? ''
  const auth = useAuth()
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()
  const {
    data: filpSettingData, isError: isFlipSettingError, isPending: isFlipSettingPending
  } = useGetFlipSetting()
  const [flipSetting, setFlipSetting] = useState<FlipSetting | null>(null)
  const {
    data: angleNameSettingData, isError: isAngleNameSettingError, isPending: isAngleNameSettingPending
  } = useGetAngleNameSetting()
  const {
    data: patientData, isError: isPatientError, isPending: isPatientPending
  } = useGetPatient(id)
  const [patient, setPatient] = useState<Patient | null>(null)
  const [angleNameSetting, setAngleNameSetting] = useState<AngleName[]>([])
  const [teethImages, setTeethImages] = useState<PhotoObject[]>(getInitialPhotos(auth.clinicid, id))
  const [examinationDate, setExaminationDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [confirmModalConfig, setConfirmModalConfig] = useState<ConfirmModalProps | undefined>()
  const [completeModalConfig, setCompleteModalConfig] = useState<CompleteModalProps | undefined>()
  const [errorModalConfig, setErrorModalConfig] = useState<ErrorModalProps | undefined>()
  const [editorModalConfig, setEditorModalConfig] = useState<PhotoEditorProps | undefined>()
  const [tempPhoto, setTempPhoto] = useState<string[]>([])
  const { mutate: saveTempMutate } = useSaveTempPhoto()
  const { mutate: uploadTempMutate } = useUploadTempPhoto()
  const { mutate: deleteTempMutate } = useDeleteTempPhoto()
  const { mutate: postMutate } = usePostPhoto()
  const { data } = useGetClinicMyself()
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])
  const imageRefs = useRef<(HTMLImageElement | null)[]>([])
  const [tabValue, setTabValue] = useState(0)
  const [operationProofing, setOperationProofing] = useState(false)
  const navigate = useNavigate()
  const resetQuery = useResetQuery()

  useEffect(() => {
    if (isFlipSettingError || isAngleNameSettingError || isPatientError) {
      console.error(isFlipSettingError)
      console.error(isAngleNameSettingError)
      console.error(isPatientError)
      return
    }

    if (!isPatientPending && patientData !== undefined) {
      setPatient(patientData.patient)
    }

    if (!isFlipSettingPending && filpSettingData !== undefined) {
      setFlipSetting(filpSettingData.flip)
    }

    if (!isAngleNameSettingPending && angleNameSettingData !== undefined) {
      setAngleNameSetting(angleNameSettingData.angleNames)
    }
  }, [
    filpSettingData, isFlipSettingError, isFlipSettingPending,
    angleNameSettingData, isAngleNameSettingError, isAngleNameSettingPending,
    patientData, isPatientError, isPatientPending,
  ])

  const getSettingAngleName = (angle: number) => {
    const setting = angleNameSetting.find(item => item.angle === angle)
    const defaultName = getAngleName(angle)
    return setting ? setting.name : defaultName
  }

  const handleLeave = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    const ret = await new Promise<ConfirmModalOnCloseProps>((resolve) => {
      setConfirmModalConfig({
        onClose: resolve,
        title: '変更は保存されていません。離脱しますか？'
      })
    })
    setConfirmModalConfig(undefined)
    if (ret.modalStatus === 'ok') {
      deleteTempMutate({ patientId: id, req: { clinicId: data?.clinic.id || '', filenames: tempPhoto } })
      navigate(`/${clinicId}/patient/${id}`)
    }
    if (ret.modalStatus === 'cancel') {
      return
    }
  }

  const handleExamDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExaminationDate(event.target.value)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleClickTeethPhoto = (angle: number) => {
    inputRefs.current[angle]?.click()
  }

  const excecuteUpload = (file: File, angle: number) => {
    setOperationProofing(true)
    const newTeethImages = [...teethImages]
    const targetAngle = newTeethImages.find((image) => image.angle === angle)
    const reader = new FileReader()
    reader.onloadend = () => {
      if (targetAngle) {
        targetAngle.loading = true
      }
      setTeethImages(newTeethImages)
    }
    reader.readAsDataURL(file)

    saveTempMutate(
      { id, req: { filename: file.name } },
      {
        onSuccess: (response: SaveTempPhotoResponse) => {
          if (targetAngle) {
            const uploadUrl = response.url
            const filename = response.filename
            const url = new URL(uploadUrl)
            const bucket = url.hostname.replace(`.s3.${env.AUTH_REGION}.amazonaws.com`, '')
            const path = url.pathname

            uploadTempMutate(
              {url: uploadUrl, req: {file: file}},
              {
                onSuccess: (_response: SaveTempPhotoResponse) => {
                  const oldPath = targetAngle.path
                  const isReplace = !targetAngle.initial
                  targetAngle.path = `${bucket}${path}`
                  targetAngle.filename = filename
                  targetAngle.initial = false
                  targetAngle.loading = false
                  if (angle === 11) {
                    targetAngle.affine = affines[flipSetting?.upperJawFlip ?? 1][flipSetting?.upperJawFlop ?? 0]
                  } else if (angle === 12) {
                    targetAngle.affine = affines[flipSetting?.lowerJawFlip ?? 1][flipSetting?.lowerJawFlop ?? 0]
                  }
                  if (isReplace) {
                    deleteTempMutate({ patientId: id, req: { clinicId: data?.clinic.id || '', filenames: [oldPath.replace(/^.*[\\/]/, '')] } })
                  }

                  setTeethImages(newTeethImages)
                  tempPhoto.push(filename)
                  setTempPhoto(tempPhoto)

                  if (inputRefs.current[angle]) {
                    (inputRefs.current[angle] as HTMLInputElement).value = ''
                  }

                  setOperationProofing(false)
                },
                onError: async (error: AxiosError<SaveTempPhotoErrorResponse>) => {
                  if (targetAngle) {
                    targetAngle.loading = false
                  }

                  setTeethImages(newTeethImages)
                  setOperationProofing(false)

                  let modalTitle = ''

                  if (error.response?.data.message === 'Request Entity Too Large') {
                    modalTitle = '画像のデータサイズが大きすぎます'
                  }

                  await new Promise<ErrorModalOnCloseProps>((resolve) => {
                    setErrorModalConfig({
                      onClose: resolve,
                      title: modalTitle
                    })
                  })
                  setErrorModalConfig(undefined)
                }
              }
            )
          }
        },
        onError: async (error: AxiosError<SaveTempPhotoErrorResponse>) => {
          if (targetAngle) {
            targetAngle.loading = false
          }
          setTeethImages(newTeethImages)
          setOperationProofing(false)
          let modalTitle = ''

          if (error.response?.data.message === 'Request Entity Too Large') {
            modalTitle = '画像のデータサイズが大きすぎます'
          }
          if (error.response?.data.errors.filename !== undefined) {
            modalTitle = error.response?.data.errors.filename[0]
          }

          await new Promise<ErrorModalOnCloseProps>((resolve) => {
            setErrorModalConfig({
              onClose: resolve,
              title: modalTitle
            })
          })
          setErrorModalConfig(undefined)
        }
      }
    )
  }

  const onChangeTeethPhoto = (angle: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      excecuteUpload(file, angle)
    }
  }

  const onDragTeethPhoto = (angle: number, files: FileList ) => {
    const file = files?.[0]
    if (file) {
      excecuteUpload(file, angle)
    }
  }

  const handleEditTeethPhoto = async (image: PhotoObject) => {
    const ret = await new Promise<EditorModalOnCloseProps>((resolve) => {
      setEditorModalConfig({
        onClose: resolve,
        image: image,
        imageRefs: imageRefs
      })
    })
    setEditorModalConfig(undefined)
    if (ret.modalStatus === 'ok') {
      const photoIndex = teethImages.findIndex((image) => image.angle === ret.image.angle)
      teethImages.splice(photoIndex, 1, ret.image)
      setTeethImages(teethImages)
      setTimeout(() => {
        imageRefs.current[image.angle]?.click()
      }, 1)
    }
    if (ret.modalStatus === 'cancel') {
      return
    }
  }

  const handleDeleteTeethPhoto = async (angle: number) => {
    const ret = await new Promise<ConfirmModalOnCloseProps>((resolve) => {
      setConfirmModalConfig({
        onClose: resolve,
        title: '削除しますか？'
      })
    })
    setConfirmModalConfig(undefined)
    if (ret.modalStatus === 'ok') {
      const targetAngle = teethImages.find((image) => image.angle === angle)
      let photoIndex = 0
      if (targetAngle?.examinationId === 0) {
        const filename = targetAngle.path.replace(/^.*[\\/]/, '')
        deleteTempMutate({ patientId: id, req: { clinicId: data?.clinic.id || '', filenames: [filename] } })
      }
      const initialTeethImage = getInitialPhotos(auth.clinicid, id).filter((image, index) => {
        const target = image.angle === angle
        photoIndex = target ? index : photoIndex
        return target
      })
      teethImages.splice(photoIndex, 1, initialTeethImage[0])
      setTeethImages(teethImages)
      setTimeout(() => {
        imageRefs.current[angle]?.click()
      }, 1)
    }
    if (ret.modalStatus === 'cancel') {
      return
    }
  }

  const handleUpload = async () => {
    const ret = await new Promise<ConfirmModalOnCloseProps>((resolve) => {
      setConfirmModalConfig({
        onClose: resolve,
        title: '更新しますか？'
      })
    })
    setConfirmModalConfig(undefined)
    if (ret.modalStatus === 'ok') {
      setOperationProofing(true)
      let request: PhotoSave = { examinationDate }
      teethImages.forEach(image => {
        if (image.initial) {
          return true
        }
        const angle = image.angle
        const filename = image.path.replace(/^.*[\\/]/, '')
        const affine = image.affine
        const json = JSON.stringify({filename, affine})
        request = {...request, ...{[`file_${angle}`]: json}}
      })
      postMutate(
        { patientId: id, req: request },
        {
          onSuccess: async (response: PostPhotoResponse) => {
            if (response.status === 'OK') {
              setOperationProofing(false)
              await new Promise<CompleteModalOnCloseProps>((resolve) => {
                setCompleteModalConfig({
                  onClose: resolve,
                  title: `更新が完了しました。`
                })
              })
              setOperationProofing(true)
              setCompleteModalConfig(undefined)

              resetQuery(['photoList'])
              resetQuery(['patientList'])
              resetQuery(['patientDetail', id])

              navigate(`/${clinicId}/patient/${id}`)
            } else {
              setOperationProofing(false)
              // エラーSnackBar
            }
          },
          onError: (error: AxiosError) => {
            console.log(error)
            setOperationProofing(false)
            // エラーSnackBar
          }
        }
      )
    }
    if (ret.modalStatus === 'cancel') {
      return
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackConfirmButton onClick={handleLeave}/>
          <Box sx={{ background: 'white', borderRadius: '16px', p: 3, mt: 1, mb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
              <Box sx={{ width: '40px', height: '40px', borderRadius: '50%', background: theme.palette.secondary.main, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ImageIcon sx={{ color: theme.palette.text.primary }} />
              </Box>
              <Typography sx={{ fontWeight: '600', fontSize: '18px', color: theme.palette.text.primary }}>アップロード</Typography>
            </Box>
            <Typography sx={{ fontSize: '16px', color: theme.palette.text.primary, mt: 2 }}>個々の画像をドラッグアンドドロップもしくは「+」を押してアップロードしてください。</Typography>
            <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)", tableLayout: "fixed", mt: 2 }}>
              <TableBody>
                <TableRow>
                  <TableCell width={72} sx={{ background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    名前
                  </TableCell>
                  <TableCell align="left" width={80} sx={{borderRight: "1px solid rgba(224, 224, 224, 1)"}}>{patient?.name}</TableCell>
                  <TableCell width={72} sx={{ background: "#F4F4F4", fontWeight: "bold" }}>
                    患者番号
                  </TableCell>
                  <TableCell align="left" width={80}>{patient?.patientNo ?? '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TextField
              id="examination_date" type="date" label="日付を選択" value={examinationDate} sx={{mt: 3, width: 200}}
              onChange={handleExamDateChange}
            />
            <Box sx={{ mt: 4, width: '100%'}}>
              <Tabs
                value={tabValue}
                centered
                variant = "fullWidth"
                onChange={handleTabChange}
                sx={{ borderBottom: "1px solid #F4F4F4" }}
              >
                <Tab label="口腔内" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="その他" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="顔" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                <Tab label="レントゲン" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
              </Tabs>
              <TabPanel tabValue={tabValue} index={0}>
                <Stack sx={{ mt: 4, display: 'flex', justifyContent: 'center' }} direction='row'>
                  {teethImages.filter((image) => [11].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {teethImages.filter((image) => [13, 10, 14].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{ mt: 2, display: 'flex', justifyContent: 'center' }} direction='row'>
                  {teethImages.filter((image) => [12].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
              </TabPanel>
              <TabPanel tabValue={tabValue} index={1}>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 4 }} direction='row'>
                  {teethImages.filter((image) => [20, 21, 22].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 4 }} direction='row'>
                  {teethImages.filter((image) => [23, 24, 25].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 4 }} direction='row'>
                  {teethImages.filter((image) => [26, 27, 28].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 4 }} direction='row'>
                  {teethImages.filter((image) => [29, 50, 51].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 4 }} direction='row'>
                  {teethImages.filter((image) => [52, 53, 54].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
              </TabPanel>
              <TabPanel tabValue={tabValue} index={2}>
                <Stack sx={{ display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 4 }} direction = 'row'>
                  {teethImages.filter((image) => [32, 30, 33].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {teethImages.filter((image) => [31, 34, 35].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {teethImages.filter((image) => [36, 37, 38].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
              </TabPanel>
              <TabPanel tabValue={tabValue} index={3}>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {teethImages.filter((image) => [40, 41].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {teethImages.filter((image) => [42, 43].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {teethImages.filter((image) => [44, 45, 46].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
                <Stack sx={{display: 'flex', justifyContent: 'center', columnGap: '24px', mt: 2 }} direction='row'>
                  {teethImages.filter((image) => [47, 48].includes(image.angle)).map((image, index) => (
                    <UploadPhotoFrame
                      image={image} inputRefs={inputRefs} imageRefs={imageRefs} key={index}
                      onChangeTeethPhoto={onChangeTeethPhoto} handleEditTeethPhoto={handleEditTeethPhoto}
                      handleDeleteTeethPhoto={handleDeleteTeethPhoto} handleClickTeethPhoto={handleClickTeethPhoto}
                      onDragTeethPhoto={onDragTeethPhoto} angleName={getSettingAngleName(image.angle)}
                    />
                  ))}
                </Stack>
              </TabPanel>
            </Box>
            <Stack sx={{ display: 'flex', alignItems: 'flex-end', mt: 4 }} >
              <Button
                onClick={handleUpload}
                size='medium'
                variant='outlined'
                sx={{ py: 1, width: '260px', color: 'white', background: theme.palette.primary.main, textAlign: 'center', fontSize: '14px', borderRadius: '40px', px: 8, fontWeight: 'bold', '&:hover': { color: theme.palette.primary.main } }}>
                  アップロードする
              </Button>
            </Stack>
          </Box>
          {confirmModalConfig && <ConfirmModal {...confirmModalConfig} />}
          {errorModalConfig && <ErrorModal {...errorModalConfig} />}
          {completeModalConfig && <CompleteModal {...completeModalConfig} />}
          {editorModalConfig && <PhotoEditor {...editorModalConfig} />}
          {operationProofing && <OperationProof initial={false} />}
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default UploadPhoto
