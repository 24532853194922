import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { Box, Button, CircularProgress, Stack, Tab, Tabs, Typography } from '@mui/material'
import { useGetPhotoListByTimelapseResponse } from 'api/photo'
import Modal from '@mui/material/Modal'
import Slider from '@mui/material/Slider'
import type { Examination, Photo } from 'api/photo/responseType'
import DiffPhotoFrame from 'components/DiffPhotoFrame'
import { getAngleName, PhotoObject } from 'types/PhotoObject'
import dayjs from 'dayjs'
import ImageRender from './ImageRender'
import { ArrowBack, ArrowForward, Close as CloseIcon } from '@mui/icons-material'
import { useWindowSize } from 'lib/useWindowSize'
import { FullScreen, FullScreenHandle, useFullScreenHandle } from 'react-full-screen'
import { Patient } from 'api/patient/responseType'

type Mark = {
  value: number
  label: string
}

export type DiffPhotoModalState = {}

export type DiffPhotoModalProps = {
  onClose: (DiffPhotoModalState: {}) => void
  patientData: Patient
  angleNum: number
  examinationId: number
  viewonly?: boolean
  angleName?: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const minDistance = 1

export const DiffPhoto = (props : DiffPhotoModalProps) => {
  const { onClose, patientData, angleNum, examinationId, viewonly = false, angleName } = props
  const [ photos, setphotos ] = useState<(Photo & Pick<Examination, 'examinationDate'>)[]>([])
  const { data: photosData, isError: isPhotosError, isPending: isPhotosPending } = useGetPhotoListByTimelapseResponse(patientData.id, angleNum)
  const [ diff, setDiff ] = useState([0, 0])
  const [ marks, setMarks ] = useState<Mark[]>([{
    value: 0,
    label: "",
  }])
  const [ viewImage, setViewImage ] = useState<Photo & Pick<Examination, 'examinationDate'> | undefined>()
  const [ viewIndex, setViewIndex ] = useState<number>(0)
  const [ viewMoved, setViewMoved ] = useState<boolean>(false)
  const [ image01, setImage01 ] = useState<PhotoObject | undefined>()
  const [ image02, setImage02 ] = useState<PhotoObject | undefined>()
  const [ imageLoaded, setImageLoaded ] = useState<boolean>(false)
  const [ tabValue, setTabValue ] = useState(0)
  const { windowWidth } = useWindowSize()
  const [ frameWidth, setFrameWidth ] = useState(900)
  const [ frameHeight, setFrameHeight ] = useState(675)
  const [ boxWidth, setBoxWidth ] = useState(1300)
  const ref = useRef<HTMLDivElement | null>(null)
  const fsHandle = useFullScreenHandle()
  const [ activeFullscreenMenu, setActiveFullscreenMenu ] = useState(false)

  const handleChangeDiff = (_event: Event, newValue: number[], activeThumb: number) => {
    if (activeThumb === 0) {
      const changed = Math.min(newValue[0], diff[1] - minDistance)
      setDiff([changed, diff[1]])
      setImage01(photos[changed])
      setImage02(photos[diff[1]])
    } else {
      const changed = Math.max(newValue[1], diff[0] + minDistance)
      setDiff([diff[0], changed])
      setImage01(photos[diff[0]])
      setImage02(photos[changed])
      setViewIndex(changed)
      setViewImage(photos[changed])
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, tabValue: number) => {
    setTabValue(tabValue)
    if (tabValue !== 0 && viewMoved) {
      if (viewIndex !== 0) {
        const diff0 = diff[0] >= viewIndex ? 0 : diff[0]
        setDiff([diff0, viewIndex])
        setImage01(photos[diff0])
        setImage02(photos[viewIndex])
      } else {
        setDiff([0, photos.length - 1])
        setImage01(photos[0])
        setImage02(photos[photos.length - 1])
      }
    } else {
      setViewMoved(false)
    }
  }

  const handleViewImageChange = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, next: boolean) => {
    const changedIndex = next ? viewIndex + 1 : viewIndex - 1
    const calcedIndex = changedIndex < 0 ? photos.length - 1 : changedIndex >= photos.length ? 0 : changedIndex
    setViewIndex(calcedIndex)
    setViewImage(photos[calcedIndex])
    setViewMoved(true)
  }

  useEffect(() => {
    if (isPhotosError) {
      console.error(isPhotosError)
      return
    }
    if(!isPhotosPending && photosData !== undefined && !imageLoaded){
      setphotos(photosData.photos)
      const marks: Mark[] = photosData.photos.map((item, index) => {
        const label = photosData.photos.length > 9 && index > 0 && index < photosData.photos.length - 1 ?
          "'" :
          (item.examinationDate ?? '')
        const marked: Mark = {
          value: index,
          label: label
        }
        return marked
      })
      setMarks(marks)
      const index = photosData.photos.findIndex((item) => item.examinationId === examinationId)
      const image02Index = index === 0 ? photosData.photos.length - 1 : index
      const newDiff = diff
      newDiff[1] = image02Index
      setDiff(newDiff)
      setImage01(Object.assign(photosData.photos[0], { initial: false }))
      setImage02(Object.assign(photosData.photos[image02Index], { initial: false }))
      setViewImage(Object.assign(photosData.photos[index], { initial: false }))
      setViewIndex(index)
      setImageLoaded(true)
    }
  }, [diff, isPhotosError, photosData, isPhotosPending, examinationId, imageLoaded ])

  useEffect(() => {
    setBoxWidth(windowWidth >= 1400 ? 1300 : (windowWidth < 1025 ? 630 : 740))
    setFrameWidth(windowWidth >= 1400 ? 900 : (windowWidth < 1025 ? 420 : 520))
    setFrameHeight(windowWidth >= 1400 ? 675 : (windowWidth < 1025 ? 315 : 390))
  }, [windowWidth])

  const getBalloonText = (value: number) => {
    if (photos.length > 0) {
      return photos[value].examinationDate ?? ''
    }
    return ''
  }

  const ImageBoxStyle = {
    width: frameWidth, height: frameHeight, fontWeight: '600',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    overflow: 'hidden', backgroundColor: 'inherit',
  }

  const pastYearMonth = useMemo(() => {
    if (!photos[diff[0]] || !photos[diff[1]]) return
    if (!image01 || !image02) return

    const dateFrom = dayjs(photos[diff[0]].examinationDate)
    const dateTo = dayjs(photos[diff[1]].examinationDate)

    const diffMonth = dateTo.diff(dateFrom, 'month')

    if (diffMonth < 0 || (diffMonth === 0 && dateFrom.unix() > dateTo.unix())) {
      return '0か月'
    }

    if (diffMonth === 0) {
      const diffDays = dateFrom.diff(dateTo, 'day')
      return `${Math.abs(diffDays)}日`
    }

    if (diffMonth >= 12) {
      const years = Math.floor(diffMonth / 12)
      const months = diffMonth % 12

      return months === 0 ? `${years}年` : `${years}年${months}か月`
    }

    return `${diffMonth}か月`
  }, [diff, photos, image01, image02])

  const handleFullScreen = () => {
    if (!fsHandle.active) {
      fsHandle.enter()
    } else {
      setActiveFullscreenMenu(!activeFullscreenMenu)
    }
  }

  const handleFullScreenClose = () => {
    if (fsHandle.active) {
      fsHandle.exit()
    }
  }

  const reportChange = useCallback((state: boolean, handle: FullScreenHandle) => {
    if (state) {
      const availHeight = window.screen.availHeight - 12
      const availWidth = window.screen.availWidth - 12
      if (availWidth > (availHeight * 4 / 3)) {
        setFrameWidth(availHeight * 4 / 3)
        setFrameHeight(availHeight)
      } else {
        setFrameWidth(availWidth)
        setFrameHeight(availWidth * 3 / 4)
      }
    } else {
      setFrameWidth(windowWidth >= 1400 ? 900 : (windowWidth < 1025 ? 420 : 520))
      setFrameHeight(windowWidth >= 1400 ? 675 : (windowWidth < 1025 ? 315 : 390))
    }
  }, [windowWidth])

  return (
    <>
      <Modal
        open onClose={() => onClose({})}
        ref={ref}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: boxWidth,
          bgcolor: '#333333',
          boxShadow: 24,
          px: 4,
          py: 2
        }}>
        {
          !viewonly ? (
              <Tabs
              value={tabValue}
              centered
              variant = "fullWidth"
              onChange={handleTabChange}
              sx={{ borderBottom: "1px solid #F4F4F4" }}
            >
              <Tab label="閲覧" sx={{ fontSize: "18px", fontWeight: "bold", color: "#F4F4F4" }}/>
              <Tab label="比較" sx={{ fontSize: "18px", fontWeight: "bold", color: "#F4F4F4" }} disabled={photos.length === 1} />
            </Tabs>  
          ) : (
            <></>
          )
        }
        <TabPanel value={tabValue} index={0}>
          <Typography color="#F4F4F4" align="center" fontSize="16px" mt={-1}>
            {angleName ?? getAngleName(angleNum)}
          </Typography>
          <Typography color="#F4F4F4" align="center" fontSize="18px">
            {viewImage?.examinationDate}
          </Typography>
          <Stack direction="row" justifyContent="center">
            {
              !viewonly ? (
                <Button onClick={(event) => handleViewImageChange(event, false)}>
                  <ArrowBack fontSize="large"/>
                </Button>
              ) : (
                <></>
              )
            }
            <FullScreen handle={fsHandle} onChange={reportChange}>
              {
                fsHandle.active && activeFullscreenMenu ?
                <Stack
                direction='row' justifyContent='center' alignItems='center' gap={2}
                  position='absolute' top={0} left={0} width={window.screen.availWidth} height={80}
                  sx={{
                    background: 'linear-gradient(rgba(0, 0, 0, 0.75) 75%, rgba(0, 0, 0, 0.0))',
                    zIndex: 9999
                  }}
                >
                  <CloseIcon
                    sx={{
                      position: 'absolute', top: 0, left: 0,
                      fontSize: 40, p: 2, color: 'white', cursor: 'pointer'
                    }}
                    onClick={handleFullScreenClose}
                  />
                  <Typography color='white' fontSize={24}>
                    {patientData?.name} ({patientData?.patientNo})
                  </Typography>
                  <Typography color='white' fontSize={24}>
                    {viewImage?.examinationDate}
                  </Typography>
                  <Typography color='white' fontSize={24}>
                    {angleName ?? getAngleName(angleNum)}
                  </Typography>
                </Stack> :
                <></>
              }
              <Box
                {...ImageBoxStyle} className='fsTarget'
                sx={{objectFit: 'cover', border: "4px solid #22BDB4", cursor: !fsHandle.active ? 'pointer' : 'default'}}
                onClick={handleFullScreen}
              >
                {
                  viewImage ?
                  <ImageRender
                    image={viewImage}
                    frameWidth={frameWidth}
                    frameHeight={frameHeight}
                  />:
                  <CircularProgress />
                }
              </Box>
            </FullScreen>
            {
              !viewonly ? (
                <Button onClick={(event) => handleViewImageChange(event, true)}>
                  <ArrowForward fontSize="large"/>
                </Button>
              ) : (
                <></>
              )
            }
          </Stack>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Typography color="#F4F4F4" align="center" fontSize="16px" mt={-1} mb={2}>
            {angleName ?? getAngleName(angleNum)}
          </Typography>
          <Stack sx={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: 4, mb: 4 }} direction = "row">
            <Box sx={{ border: "4px solid #22BDB4"}}>
              <DiffPhotoFrame
                image={image01}
              />
            </Box>
            <Box sx={{ border: "4px solid #EAB221"}}>
              <DiffPhotoFrame
                image={image02}
              />
            </Box>
          </Stack>
          <Box sx={{ textAlign: '-webkit-center', color: 'white' }}>{pastYearMonth}</Box>
          <Box sx={{
            width: windowWidth < 1025 ? '80%': '90%',
            margin:  '0 auto 16px auto',
          }}>
            <Slider
              step={1}
              min={0}
              max={marks.length - 1}
              marks={marks}
              defaultValue={diff}
              value={diff}
              onChange={handleChangeDiff}
              valueLabelDisplay="auto"
              track={false}
              disableSwap
              getAriaValueText={getBalloonText}
              valueLabelFormat={getBalloonText}
              sx={{
                '& .MuiSlider-markLabel': {color: 'white'},
                '& .MuiSlider-thumb[data-index="1"]' : {background: '#EAB221'}
              }}
            />
          </Box>       
        </TabPanel>
        </Box>
      </Modal>
    </>
  )
}
export default DiffPhoto
