import React, {useState, useEffect, useCallback, useMemo} from 'react'
import { Typography, Box, Stack, Button, CircularProgress, Snackbar, Alert, Tooltip } from '@mui/material'
import Layout from '../../components/Layout'
import { ThemeProvider } from '@mui/material/styles'
import PageBackButton from '../../components/PageBackButton'
import theme from '../../style/theme'
import { useLocation, useNavigate } from "react-router-dom"
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { NavLink } from 'react-router-dom';
import Tabs from '@mui/material/Tabs'
import Tab from "@mui/material/Tab"
import { useParams } from "react-router-dom"
import { useGetPatient } from '../../api/patient'
import type { Patient }  from '../../api/patient/responseType'
import { useGetAllPhoto, useGetExaminationList } from '../../api/photo'
import type { Examination }  from '../../api/photo/responseType'
import PatientPhotoItem from './PatientPhotoItem'
import PatientPhotoSubItem from './PatientPhotoSubItem'
import PatientPhotoFaceItem from './PatientPhotoFaceItem'
import PatientPhotoXrayItem from './PatientPhotoXrayItem'
import useSecretModeFlag from 'hooks/useSecretModeFlag'
import { sexValues } from 'types/PatientObjects'
import { generatePatientStates } from 'lib/patientService'
import OperationProof from 'components/OperationProof'
import PatientStatusBar from './PatientStatusBar'
import dayjs from 'dayjs'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { getImageUrl } from 'lib/getImagePath'
import PatientPhotoStorage from './PatientPhotoStorage'
import { useGetClinicMyself } from 'api/clinic'
import { Clinic } from 'api/clinic/responseType'
import AvatarRender from 'components/AvatarRender'
import { defaultAffine, getAngleName } from 'types/PhotoObject'
import { useGetAngleNameSetting, useGetExportSetting } from 'api/setting'
import { AngleName, ExportSetting } from 'api/setting/responseType'
import ConfirmExportModal, { ConfirmExportModalOnCloseProps, ConfirmExportModalProps } from 'components/ConfirmExportModal'
import { useGetExportStatus, usePostExport } from 'api/export'
import { AxiosError } from 'axios'
import { GetExportListResponse } from 'api/export/responseType'
import useResetQuery from 'api/useResetQuery'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

type PatientDetailProps = {
  id: string
  clinicId: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const PatientDetail = () => {
  const [value, setValue] = useState(0)
  const navigate = useNavigate()
  const loc = useLocation()
  const urlParams = useParams<PatientDetailProps>()
  const id = urlParams.id ? parseInt(urlParams.id) : 0
  const clinicId = urlParams.clinicId ?? ''
  const [confirmExportModalConfig, setConfirmExportModalConfig] = useState<ConfirmExportModalProps | undefined>()
  const [ items, setItems ] = useState<Patient | null>(null)
  const [ examinations, setExaminations ] = useState<Examination[]>([])
  const [ allPhotos, setAllPhotos ] = useState<Examination[]>([])
  const [ clinic, setClinic ] = useState<Clinic | null>(null)
  const {
    data: patientData, isError: isPatientError, isPending: isPatientPending,
    isRefetching: isPatientRefetchPending, isRefetchError: isPatientRefetchError
  } = useGetPatient(id)
  const {
    data: photoData, isError: isExaminationError, isPending: isExaminationPending,
    isRefetching: isExaminationRefetchPending, isRefetchError: isExaminationRefetchError
  } = useGetExaminationList(id)
  const {
    data: allPhotoData, isError: isAllPhotoError, isPending: isAllPhotoPending
  } = useGetAllPhoto(id)
  const {
    data: clinicData, isError: isClinicError, isPending: isClinicPending
  } = useGetClinicMyself()
  const { flag, toggleFlag } = useSecretModeFlag(false)
  const {
    data: angleNameSettingData, isError: isAngleNameSettingError, isPending: isAngleNameSettingPending
  } = useGetAngleNameSetting()
  const [angleNameSetting, setAngleNameSetting] = useState<AngleName[]>([])
  const {
    data: exportSettingData, isError: isExportSettingError, isPending: isExportSettingPending
  } = useGetExportSetting()
  const [ exportSetting, setExportSetting ] = useState<ExportSetting | null>(null)
  const {
    data: exportStatusData, isError: isExportStatusError, isPending: isExportStatusPending
  } = useGetExportStatus()
  const [ exportProcessing, setExportProcessing ] = useState<boolean>(false)
  const {mutate: startExport} = usePostExport()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarFlag, setSnackBarFlag] = useState('')
  const resetQuery = useResetQuery()

  const hideText = '*****'

  const previosPagePath = useMemo(() => {
    return loc.state ?? `/${clinicId}/patient`
  }, [clinicId, loc.state])

  const obfuscatePatient = useCallback((patient: Patient) => {
    return {
      ...patient,
      name: flag ? patient.name.substr(0, 1) + hideText : patient.name,
      kana: flag ? patient.kana.substr(0, 1) + hideText : patient.kana,
    }
  }, [flag])

  const handleUpload = (patientId: number) => {
    navigate(`/${clinicId}/patient/upload/${patientId}`)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const calculateAge = useCallback((date: string | undefined) => {
    if (!date || date === undefined || flag) {
      return `${hideText}歳 ${hideText}ヶ月`
    }

    const converted = dayjs(date)
    const ageYear = dayjs().diff(converted, 'year')
    const ageTotalMonth = dayjs().diff(converted, 'month')
    const ageMonth = !isNaN(ageTotalMonth) ? ageTotalMonth % 12 : 0

    return !isNaN(ageYear) ? `${ageYear}歳 ${ageMonth}ヶ月` : ''
  }, [flag])

  const getImagePath = useCallback(() => {
    const path = patientData?.patient.face

    if (path === undefined || !path || flag) {
      return ''
    }

    return getImageUrl(path)
  }, [patientData, flag])

  const getFaceAffine = useCallback(() => {
    const affine = patientData?.patient.affine

    if (affine === undefined || !affine || flag) {
      return defaultAffine
    }

    return affine
  }, [patientData, flag])

  const imagePath = getImagePath()
  const affine = getFaceAffine()

  useEffect(() => {
    if (
      isPatientError || isExaminationError
      || isPatientRefetchError || isExaminationRefetchError
      || isAllPhotoError || isClinicError || isAngleNameSettingError
      || isExportSettingError || isExportStatusError
    ) {
      console.error(isPatientError)
      console.error(isExaminationError)
      console.error(isPatientRefetchError)
      console.error(isExaminationRefetchError)
      console.error(isAllPhotoError)
      console.error(isClinicError)
      console.error(isAngleNameSettingError)
      console.error(isExportSettingError)
      console.error(isExportStatusError)
      return
    }
    if(!isPatientPending && !isPatientRefetchPending && patientData !== undefined){
      setItems(obfuscatePatient(patientData.patient))
    }
    if(!isExaminationPending && !isExaminationRefetchPending && photoData !== undefined){
      setExaminations(photoData.examinations)
    }
    if(!isAllPhotoPending && allPhotoData !== undefined){
      setAllPhotos(allPhotoData?.examinations)
    }
    if(!isClinicPending && clinicData !== undefined){
      setClinic(clinicData.clinic)
    }
    if (!isAngleNameSettingPending && angleNameSettingData !== undefined) {
      setAngleNameSetting(angleNameSettingData.angleNames)
    }
    if (!isExportSettingPending && exportSettingData !== undefined) {
      setExportSetting(exportSettingData.export)
    }
    if (!isExportStatusPending && exportStatusData !== undefined) {
      setExportProcessing(exportStatusData.processing)
    }
  }, [
    isPatientError,
    patientData, isPatientPending, isExaminationError,
    photoData, isExaminationPending, isPatientRefetchPending,
    isPatientRefetchError, isExaminationRefetchPending, isExaminationRefetchError,
    allPhotoData, isAllPhotoError, isAllPhotoPending,
    clinicData, isClinicError, isClinicPending,
    angleNameSettingData, isAngleNameSettingError, isAngleNameSettingPending,
    exportSettingData, isExportSettingError, isExportSettingPending,
    exportStatusData, isExportStatusError, isExportStatusPending,
    obfuscatePatient,
  ])

  if (isPatientPending || isPatientRefetchPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  const getSettingAngleName = (angle: number) => {
    const setting = angleNameSetting.find(item => item.angle === angle)
    const defaultName = getAngleName(angle)
    return setting ? setting.name : defaultName
  }

  const handelPatientExport = async () => {
    const ret = await new Promise<ConfirmExportModalOnCloseProps>((resolve) => {
      setConfirmExportModalConfig({
        onClose: resolve,
        setting: exportSetting,
        clinicId: clinicId,
        target: items?.name + ' （' + (items?.patientNo ?? '患者番号なし') + '）'
      })
    })
    setConfirmExportModalConfig(undefined)
    if (ret.modalStatus === 'ok') {
      startExport(
        { 
          req: {
            exportType: 1,
            identifier: id
          }
        },
        {
          onSuccess: (response) => {
            if (response.status === 'OK') {
              setSnackBarOpen(true)
              setSnackBarMessage('エクスポートを開始しました。')
              setSnackBarFlag('success')
              resetQuery(['exportList'])
              resetQuery(['exportStatus'])
            } else {
              setSnackBarOpen(true)
              setSnackBarMessage('エラーが発生しました。')
              setSnackBarFlag('error')
            }
          },
          onError: (error: AxiosError) => {
            console.error(error)
            const message = error.response?.data ?? 'エラー 422'
            setSnackBarOpen(true)
            setSnackBarMessage(`${message}`)
            setSnackBarFlag('error')
          }
        }
      )
    }
    if (ret.modalStatus === 'cancel') {
      return
    }
  }

  const handelExaminationExport = async (patientData: Patient, examinationData: Examination) => {
    const ret = await new Promise<ConfirmExportModalOnCloseProps>((resolve) => {
      setConfirmExportModalConfig({
        onClose: resolve,
        setting: exportSetting,
        clinicId: clinicId,
        target: patientData?.name + ' （' + (items?.patientNo ?? '患者番号なし') + '） ' + examinationData.examinationDate
      })
    })
    setConfirmExportModalConfig(undefined)
    if (ret.modalStatus === 'ok') {
      startExport(
        { 
          req: {
            exportType: 2,
            identifier: examinationData.id
          }
         },
        {
          onSuccess: (response: GetExportListResponse) => {
            if (response.status === 'OK') {
              setSnackBarOpen(true)
              setSnackBarMessage('エクスポートを開始しました。')
              setSnackBarFlag('success')
              resetQuery(['exportList'])
              resetQuery(['exportStatus'])
            } else {
              setSnackBarOpen(true)
              setSnackBarMessage('エラーが発生しました。')
              setSnackBarFlag('error')
            }
          },
          onError: (error: AxiosError) => {
            console.error(error)
            const message = error.response?.data ?? 'エラー 422'
            setSnackBarOpen(true)
            setSnackBarMessage(`${message}`)
            setSnackBarFlag('error')
          }
        }
      )
    }
    if (ret.modalStatus === 'cancel') {
      return
    }
  }

  const handleSnackClose = () => {
    setSnackBarOpen(false)
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={previosPagePath} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1, mb: 4 }}>
            <Stack sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} direction = "row">
                <ListItem alignItems="center" sx={{ display: "flex", columnGap: "24px", p: 0 }}>
                  <AvatarRender imagePath={imagePath} affine={affine} frameWidth={64} frameHeight={64} />
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: theme.palette.text.primary }}>
                      {items?.kana}
                    </Typography>
                    <Typography sx={{ fontSize: "24px", fontWeight: "bold", color: theme.palette.text.primary }}>
                      {items?.name}
                    </Typography>
                  </Box>
                  <IconButton onClick={toggleFlag} sx={{ p: 0, "&:hover": { backgroundColor: "transparent" }, border: "1px solid #A8A8A8", width: "40px", height: "40px" }}>
                    {flag ? <VisibilityOffIcon sx={{ color: "#A8A8A8", minWidth: "40px" }} /> : <VisibilityIcon sx={{ color: "#A8A8A8", minWidth: "40px" }} />}
                  </IconButton>
                </ListItem>
                <Stack sx={{ display: "flex", columnGap: "16px" }} direction = "row">
                  <Button
                    variant="outlined"
                    onClick={() => handleUpload(items?.id ?? 0)}
                    sx={{ py: 0.5 , px: 2 ,background: theme.palette.primary.main , color: "white", borderRadius: "60px", fontWeight: "bold", width: "160px", fontSize: "14px", "&:hover": { backgroundColor: "white", color: theme.palette.primary.main } }}
                  >
                    アップロード
                  </Button>
                  <Button
                    variant="outlined"
                    component={NavLink}
                    to={`/${clinicId}/patient/${id}/comparison`}
                    sx={{ py: 0.5 , px: 2 ,background: "white" , color: theme.palette.primary.main, borderColor: theme.palette.primary.main , borderRadius: "30px", fontWeight: "bold", width: "96px", fontSize: "14px", "&:hover": { backgroundColor: theme.palette.primary.main, color: "white" } }}
                  >
                    比較写真
                  </Button>
                  <Tooltip
                    disableHoverListener={!exportProcessing}
                    disableFocusListener={!exportProcessing}
                    disableTouchListener={!exportProcessing}
                    disableInteractive={true}
                    title="進行中のエクスポートがあります。同時に複数のエクスポートは実行できません。"
                  >
                    <Box>
                      <Button
                        variant="outlined"
                        onClick={handelPatientExport}
                        disabled={!!exportProcessing}
                        sx={{ py: 0.5 , px: 2 ,background: "white" , color: theme.palette.primary.main, borderColor: theme.palette.primary.main , borderRadius: "30px", fontWeight: "bold", width: "160px", fontSize: "14px", "&:hover": { backgroundColor: theme.palette.primary.main, color: "white" } }}
                      >
                        エクスポート
                      </Button>
                    </Box>
                  </Tooltip>
                  <Button
                    variant="outlined"
                    component={NavLink}
                    to={`/${clinicId}/patient/${id}/edit`}
                    sx={{ py: 0.5 , px: 2 ,background: "white" , color: theme.palette.primary.main, borderColor: theme.palette.primary.main , borderRadius: "30px", fontWeight: "bold", width: "160px", fontSize: "14px", "&:hover": { backgroundColor: theme.palette.primary.main, color: "white" } }}
                  >
                    プロフィール編集
                  </Button>
                </Stack>
            </Stack>
            <Stack sx={{ p: 0, mt: 4 ,display: "flex", justifyContent: "space-between", alignItems: "flex-start" }} direction="row">
              <Table sx={{ brder: "1px solid rgba(224, 224, 224, 1)", width:"44%" }}>
                <TableBody sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)", borderRight: "1px solid rgba(224, 224, 224, 1)", borderLeft: "1px solid rgba(224, 224, 224, 1)"}} >
                    <TableRow>
                      <TableCell sx={{  p:1, width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)"}}>
                        性別
                      </TableCell>
                      <TableCell align="left"sx={{ p:1 }}>{sexValues.find((item) => item.key === (items?.sex ?? 0))?.title}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{  p:1, width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                        患者番号
                      </TableCell>
                      <TableCell align="left" sx={{ p:1 }}>{items?.patientNo ?? '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{  p:1, width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                        年齢
                      </TableCell>
                      <TableCell align="left" sx={{ p:1 }}>{calculateAge(items?.birthday)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{  p:1, width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                        生年月日
                      </TableCell>
                      <TableCell align="left" sx={{ p:1 }}>{items?.birthday}</TableCell>
                    </TableRow>                    
                    <TableRow>
                      <TableCell sx={{ p:1, width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                        状態
                      </TableCell>
                      <TableCell align="left" sx={{ p:1 }}>{generatePatientStates(items)}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
              <Table sx={{ width:"54%" }}>
                <TableBody sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)", borderRight: "1px solid rgba(224, 224, 224, 1)", borderLeft: "1px solid rgba(224, 224, 224, 1)"}} >
                  <TableRow>
                    <TableCell sx={{ p:1, width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    ステータス
                    </TableCell>
                    <TableCell sx={{ p:1 }}>
                      <PatientStatusBar
                        patientId={id} status={items?.status ?? 0} detailMode={true}
                        fmeDate={items?.fmeDate ?? ''} insDate={items?.insDate ?? ''}
                        diaDate={items?.diaDate ?? ''} atDate={items?.atDate ?? ''}
                        retDate={items?.retDate ?? ''} cmpDate={items?.cmpDate ?? ''}
                        setStatus={() => {}}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
            {
              isExaminationPending || isExaminationRefetchPending ?
              <Stack justifyContent="center" alignItems="center" width={1 / 1} mt={1}>
                <CircularProgress />
              </Stack>
              :
              <Box sx={{ mt: 4, width: '100%'}}>
                <Tabs
                  value={value}
                  centered
                  variant = "fullWidth"
                  onChange={handleChange}
                  sx={{ borderBottom: "1px solid #F4F4F4" }}
                >
                  <Tab label="口腔内" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                  <Tab label="その他" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                  <Tab label="顔" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                  <Tab label="レントゲン" sx={{ fontSize: "18px", fontWeight: "bold" }}/>
                  <Tab label="保管庫" sx={{ fontSize: "18px", fontWeight: "bold", display: clinic?.isMigrated ? 'box' : 'none' }}/>
                </Tabs>
                <TabPanel value={value} index={0} >
                  {examinations.map((examination, index) => {
                    return (
                      items &&
                      <PatientPhotoItem
                        examinationData={examination} patientData={items}
                        angleNameGetter={getSettingAngleName}
                        handleExport={handelExaminationExport}
                        exportProcessing={exportProcessing} key={index}
                      />
                    )}
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {examinations.map((examination, index) => {
                    return (
                      items &&
                      <PatientPhotoSubItem
                        examinationData={examination} patientData={items}
                        angleNameGetter={getSettingAngleName}
                        handleExport={handelExaminationExport}
                        exportProcessing={exportProcessing} key={index}
                      />
                    )}
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {examinations.map((examination, index) => {
                    return (
                      items &&
                      <PatientPhotoFaceItem
                        examinationData={examination} patientData={items}
                        angleNameGetter={getSettingAngleName}
                        handleExport={handelExaminationExport}
                        exportProcessing={exportProcessing} key={index}
                      />
                    )}
                  )}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  {examinations.map((examination, index) => {
                    return (
                      items &&
                      <PatientPhotoXrayItem
                        examinationData={examination} patientData={items}
                        angleNameGetter={getSettingAngleName}
                        handleExport={handelExaminationExport}
                        exportProcessing={exportProcessing} key={index}
                      />
                    )}
                  )}
                </TabPanel>
                {
                  clinic?.isMigrated ? (
                    <TabPanel value={value} index={4}>
                      {allPhotos.map((allPhoto, index) => {
                        return (
                          items &&
                          <PatientPhotoStorage
                            examinationData={allPhoto} patientData={items}
                            angleNameGetter={getSettingAngleName}
                            handleExport={handelExaminationExport}
                            exportProcessing={exportProcessing} key={index}
                          />
                        )}
                      )}
                    </TabPanel>
                  ) : (
                    <></>
                  )
                }
              </Box>
            }
          </Box>
          {confirmExportModalConfig && <ConfirmExportModal {...confirmExportModalConfig} />}
          <Snackbar
              autoHideDuration={3000}
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              open={snackBarOpen}
              onClose={handleSnackClose}
          >
            <Alert
              severity={snackBarFlag === 'error' ? 'error' : 'success'}
              sx={{ width: '100%' }}
              variant="filled"
            >{snackBarMessage}</Alert>
          </Snackbar>
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default PatientDetail
