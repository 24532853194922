import { Patient } from 'api/patient/responseType'
import { Examination, Photo } from 'api/photo/responseType'

export type PhotoObject = Photo & {
  initial?: boolean
  anglename?: string
  filename?: string
  loading?: boolean
}

export type PatientPhotoItemProps = {
  examinationData: Examination
  patientData: Patient
  angleNameGetter: Function
  handleExport: Function
  exportProcessing: boolean
}

export const defaultAffine = {flip: false, flop: false, rotate: 0, scale: 1, translateX: 0, translateY: 0}
export const flipedAffine = {flip: true, flop: false, rotate: 0, scale: 1, translateX: 0, translateY: 0}
export const flopedAffine = {flip: false, flop: true, rotate: 0, scale: 1, translateX: 0, translateY: 0}
export const fliflopedAffine = {flip: true, flop: true, rotate: 0, scale: 1, translateX: 0, translateY: 0}

export const affines = [
  [defaultAffine, flopedAffine],
  [flipedAffine, fliflopedAffine],
]

export const getAngleName = (angle: number) => {
  return {
    10: '口腔内 正面',
    11: '口腔内 上顎',
    12: '口腔内 下顎',
    13: '口腔内 右',
    14: '口腔内 左',
    15: '口腔内 斜め右',
    16: '口腔内 斜め左',
    20: 'その他①',
    21: 'その他②',
    22: 'その他③',
    23: 'その他④',
    24: 'その他⑤',
    25: 'その他⑥',
    26: 'その他⑦',
    27: 'その他⑧',
    28: 'その他⑨',
    29: 'その他⑩',
    30: '顔・体 正面',
    31: '顔・体 斜め',
    32: '顔・体 側面',
    33: '顔・体 笑顔',
    34: 'その他①',
    35: 'その他②',
    36: 'その他③',
    37: 'その他④',
    38: 'その他⑤',
    39: 'その他⑥',
    40: 'レントゲン パノラマ',
    41: 'レントゲン PA',
    42: 'レントゲン LA',
    43: 'レントゲン CT',
    44: 'レントゲン デンタル1',
    45: 'レントゲン デンタル2',
    46: 'レントゲン デンタル3',
    47: 'レントゲン デンタル4',
    48: 'レントゲン デンタル5',
    49: 'レントゲン 体軸',
    50: 'その他⑪',
    51: 'その他⑫',
    52: 'その他⑬',
    53: 'その他⑭',
    54: 'その他⑮',
    55: 'その他⑯',
    56: 'その他⑰',
    57: 'その他⑱',
    58: 'その他⑲',
    59: 'その他⑳',
    90: 'その他①',
    91: 'その他②',
    92: 'その他③',
    93: 'その他④',
    94: 'その他⑤',
    95: 'その他⑥',
    96: 'その他⑦',
    97: 'その他⑧',
    98: 'その他⑨',
    99: 'その他⑩',
  }[angle]
}

export const getInitialPhotos = (clinicId: string, patientId: number): PhotoObject[] => {
  const affine = defaultAffine
  return [
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 11, anglename: '口腔内 上顎', path: '/images/ref-teeth01.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 13, anglename: '口腔内 右', path: '/images/ref-teeth02.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 10, anglename: '口腔内 正面', path: '/images/ref-teeth03.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 14, anglename: '口腔内 左', path: '/images/ref-teeth04.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 12, anglename: '口腔内 下顎', path: '/images/ref-teeth05.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 20, anglename: 'その他①', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 21, anglename: 'その他②', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 22, anglename: 'その他③', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 23, anglename: 'その他④', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 24, anglename: 'その他⑤', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 25, anglename: 'その他⑥', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 26, anglename: 'その他⑦', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 27, anglename: 'その他⑧', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 28, anglename: 'その他⑨', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 29, anglename: 'その他⑩', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 50, anglename: 'その他⑪', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 51, anglename: 'その他⑫', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 52, anglename: 'その他⑬', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 53, anglename: 'その他⑭', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 54, anglename: 'その他⑮', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 55, anglename: 'その他⑯', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 56, anglename: 'その他⑰', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 57, anglename: 'その他⑱', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 58, anglename: 'その他⑲', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 59, anglename: 'その他⑳', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 32, anglename: '顔・体 側面', path: '/images/ref-face02.png', filename: '',
    },    
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 30, anglename: '顔・体 正面', path: '/images/ref-face01.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 33, anglename: '顔・体 笑顔', path: '/images/ref-face03.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 31, anglename: '顔斜め', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 34, anglename: 'その他①', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 35, anglename: 'その他②', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 36, anglename: 'その他③', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 37, anglename: 'その他④', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 38, anglename: 'その他⑤', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 39, anglename: 'その他⑥', path: '', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 40, anglename: 'レントゲン パノラマ', path: '/images/ref-xray-panorama.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 41, anglename: 'レントゲン PA', path: '/images/ref-xray-pa.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 42, anglename: 'レントゲン LA', path: '/images/ref-xray-la.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 43, anglename: 'レントゲン CT', path: '/images/ref-xray-ct.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 44, anglename: 'レントゲン デンタル①', path: '/images/ref-xray-dental.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 45, anglename: 'レントゲン デンタル②', path: '/images/ref-xray-dental.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 46, anglename: 'レントゲン デンタル③', path: '/images/ref-xray-dental.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 47, anglename: 'レントゲン デンタル④', path: '/images/ref-xray-dental.png', filename: '',
    },
    {
      initial: true, clinicId, patientId, examinationId: 0, affine, id: 0,
      angle: 48, anglename: 'レントゲン 手', path: '/images/ref-hand.png', filename: '',
    }
  ]
}

export const getAlterPath = (angle: number) => {
  return {
    10: `${process.env.PUBLIC_URL}/images/ref-teeth03.png`,
    11: `${process.env.PUBLIC_URL}/images/ref-teeth01.png`,
    12: `${process.env.PUBLIC_URL}/images/ref-teeth05.png`,
    13: `${process.env.PUBLIC_URL}/images/ref-teeth02.png`,
    14: `${process.env.PUBLIC_URL}/images/ref-teeth04.png`,
    30: `${process.env.PUBLIC_URL}/images/ref-face02.png`,
    32: `${process.env.PUBLIC_URL}/images/ref-face01.png`,
    33: `${process.env.PUBLIC_URL}/images/ref-face03.png`,
    40: `${process.env.PUBLIC_URL}/images/ref-xray-panorama.png`,
    41: `${process.env.PUBLIC_URL}/images/ref-xray-pa.png`,
    42: `${process.env.PUBLIC_URL}/images/ref-xray-la.png`,
    43: `${process.env.PUBLIC_URL}/images/ref-xray-ct.png`,
    44: `${process.env.PUBLIC_URL}/images/ref-xray-dental.png`,
    45: `${process.env.PUBLIC_URL}/images/ref-xray-dental.png`,
    46: `${process.env.PUBLIC_URL}/images/ref-xray-dental.png`,
    47: `${process.env.PUBLIC_URL}/images/ref-xray-dental.png`,
    48: `${process.env.PUBLIC_URL}/images/ref-hand.png`
  }[angle]
}

export const getTabName = (angle: number) => {
  if ([10, 11, 12, 13, 14].includes(angle)) {
    return '口腔内'
  } else if ([20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 50, 51, 52, 53, 54].includes(angle)) {
    return 'その他'
  } else if ([30, 31, 32, 33, 34, 35, 36, 37, 38].includes(angle)) {
    return '顔'
  } else if ([40, 41, 42, 43, 44, 45, 46, 47, 48].includes(angle)) {
    return 'レントゲン'
  } else {
    return '保管庫'
  }
}