import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import type { Examination, Photo } from 'api/photo/responseType'
import { getAngleName } from 'types/PhotoObject'
import ImageRender from './ImageRender'
import { useWindowSize } from 'lib/useWindowSize'
import { FullScreen, FullScreenHandle, useFullScreenHandle } from 'react-full-screen'
import { Patient } from 'api/patient/responseType'

export type ExpandedPhotoModalState = {}

export type ExpandedPhotoModalProps = {
  onClose: (ExpandedPhotoModalState: {}) => void
  photo: Photo
  patientData: Patient | null
  examinationData: Examination
  angleName?: string
}


export const ExpandedPhoto = (props : ExpandedPhotoModalProps) => {
  const { onClose, photo, patientData, examinationData, angleName } = props
  const { windowWidth } = useWindowSize()
  const ref = useRef<HTMLDivElement | null>(null)
  const [ frameWidth, setFrameWidth ] = useState(900)
  const [ frameHeight, setFrameHeight ] = useState(675)
  const [ boxWidth, setBoxWidth ] = useState(1300)
  const fsHandle = useFullScreenHandle()
  const [ activeFullscreenMenu, setActiveFullscreenMenu ] = useState(false)

  useEffect(() => {
    setFrameWidth(windowWidth >= 1400 ? 900 : (windowWidth < 1025 ? 420 : 520))
    setFrameHeight(windowWidth >= 1400 ? 675 : (windowWidth < 1025 ? 315 : 390))
    setBoxWidth(windowWidth >= 1400 ? 1300 : (windowWidth < 1025 ? 630 : 740))
  }, [windowWidth])

  const handleFullScreen = () => {
    if (!fsHandle.active) {
      fsHandle.enter()
    } else {
      console.log('meow')
      setActiveFullscreenMenu(!activeFullscreenMenu)
    }
  }

  const handleFullScreenClose = () => {
    if (fsHandle.active) {
      fsHandle.exit()
    }
  }

  const reportChange = useCallback((state: boolean, handle: FullScreenHandle) => {
    if (state) {
      const availHeight = window.screen.availHeight - 12
      const availWidth = window.screen.availWidth - 12
      if (availWidth > (availHeight * 4 / 3)) {
        setFrameWidth(availHeight * 4 / 3)
        setFrameHeight(availHeight)
      } else {
        setFrameWidth(availWidth)
        setFrameHeight(availWidth * 3 / 4)
      }
    } else {
      setFrameWidth(windowWidth >= 1400 ? 900 : (windowWidth < 1025 ? 420 : 520))
      setFrameHeight(windowWidth >= 1400 ? 675 : (windowWidth < 1025 ? 315 : 390))
    }
  }, [windowWidth])

  const ImageBoxStyle = {
    width: frameWidth, height: frameHeight, fontWeight: '600',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    overflow: 'hidden', backgroundColor: 'inherit'
  }

  return (
    <>
      <Modal
        open onClose={() => onClose({})}
        ref={ref}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: boxWidth,
          bgcolor: '#333333',
          boxShadow: 24,
          px: 4,
          py: 2
        }}>
          <Typography color="#F4F4F4" align="center" fontSize="16px" mt={-1}>
            {angleName ?? getAngleName(photo.angle)}
          </Typography>
          <Typography color="#F4F4F4" align="center" fontSize="18px">
            {examinationData.examinationDate}
          </Typography>
          <Stack direction="row" justifyContent="center">
            <FullScreen handle={fsHandle} onChange={reportChange}>
              {
                fsHandle.active && activeFullscreenMenu ?
                <Stack
                  direction='row' justifyContent='center' alignItems='center' gap={2}
                  position='absolute' top={0} left={0} width={window.screen.availWidth} height={80}
                  sx={{
                    background: 'linear-gradient(rgba(0, 0, 0, 0.75) 75%, rgba(0, 0, 0, 0.0))',
                    zIndex: 9999
                  }}
                >
                  <CloseIcon
                    sx={{
                      position: 'absolute', top: 0, left: 0,
                      fontSize: 40, p: 2, color: 'white', cursor: 'pointer'
                    }}
                    onClick={handleFullScreenClose}
                  />
                  <Typography color='white' fontSize={24}>
                    {patientData?.name} ({patientData?.patientNo})
                  </Typography>
                  <Typography color='white' fontSize={24}>
                    {examinationData.examinationDate}
                  </Typography>
                  <Typography color='white' fontSize={24}>
                    {angleName ?? getAngleName(photo.angle)}
                  </Typography>
                </Stack> :
                <></>
              }
              <Box
                {...ImageBoxStyle} className='fsTarget'
                sx={{objectFit: 'cover', border: "4px solid #22BDB4", cursor: !fsHandle.active ? 'pointer' : 'default'}}
                onClick={handleFullScreen}
              >
                {
                  photo ?
                  <ImageRender
                    image={photo}
                    frameWidth={frameWidth}
                    frameHeight={frameHeight}
                  />:
                  <CircularProgress />
                }
              </Box>
            </FullScreen>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
export default ExpandedPhoto
